import {
  CalculationCast,
  CalculationSide,
  CalculationSideCastType,
  CalculationStage,
  ContactFormData,
  FastCalculationSide,
  FastOrderCast,
  RESET_CALCULATION,
  RESET_SENDER_RECEIVER_FAST,
  ResetSenderReceiverFast,
  SET_CALCULATION_STAGE,
  SET_CURRENT_RECEIVER_TERMINAL,
  SET_CURRENT_SENDER_TERMINAL,
  SET_FAST_ORDER_CAST,
  SET_RECEIVER_CAST,
  SET_RECEIVER_FAST_CAST,
  SET_SENDER_CAST,
  SET_SENDER_FAST_CAST,
  SetCalculationStage,
  SetCurrentReceiverTerminal,
  SetCurrentSenderTerminal,
  SetFastOrderCast,
  SetReceiverCast,
  SetReceiverFastCast,
  SetSenderCast, SetSenderFastCast, TerminalCast,
} from './redux';
import { ThunkAction } from '../../util/types';
import { complexForeignAddressCreate, contactCastComplexCreate, updateInternationalContactCast } from '../contact/action-creators';
import { adaptToApi } from '../../util/adapter';
import { COUNTRIES_CIS, MODULE_NAME } from './constant';
import { AdditionalServiceCode } from '../cargo/types';
import { RateTypeChoice, SingleRate } from '../calculation/types';
import {
  calculationResultPolling,
  resetCalculation as originalResetCalculation,
  resetFastCalculation as resetFastOriginalCalculation,
  setCalculationDone,
  setCalculationPollingTimer,
  setCalculationResults,
  setCalculationRunning,
  setCalculationTaskId,
  setRecalculationDone,
  setRecalculationResults,
  setRecalculationRunning,
  setRecalculationTaskId,
  updateServicesFilter,
} from '../calculation/action-creators';
import { createReturnCargo, updateCurrentCargo } from '../cargo/action-creators';
import { executeAll } from '../../util/promise';
import { SERVER_DATE_FORMAT } from '../../util/constants';
import { DELIVERY_WAY_SERVICES } from '../calculation/constants';
import { getGroupName } from '../../util/helpers';
import { setAdditionalServiceDescList } from '../reference/action-creators';

export const setSenderCast = (cast: CalculationSide | null): SetSenderCast => ({
  type: SET_SENDER_CAST,
  cast,
});

export const setReceiverCast = (cast: CalculationSide | null): SetReceiverCast => ({
  type: SET_RECEIVER_CAST,
  cast,
});

export const setSenderFastCast = (cast: FastCalculationSide | null): SetSenderFastCast => ({
  type: SET_SENDER_FAST_CAST,
  cast,
});

export const setReceiverFastCast = (cast: FastCalculationSide | null): SetReceiverFastCast => ({
  type: SET_RECEIVER_FAST_CAST,
  cast,
});

export const setCalculationStage = (stage: CalculationStage): SetCalculationStage => ({
  type: SET_CALCULATION_STAGE,
  stage,
});

export const resetCalculation = (): ThunkAction<void> => (dispatch) => {
  dispatch(originalResetCalculation());
  dispatch({
    type: RESET_CALCULATION,
    isFastOrder: false
  });
};

export const resetFastCalculation = (isFastOrder: boolean): ThunkAction<void> => (dispatch) => {
  dispatch(resetFastOriginalCalculation(isFastOrder));
  dispatch({
    type: RESET_CALCULATION,
    isFastOrder
  });
};

export const setCurrentSenderTerminal = (terminal: TerminalCast | null): SetCurrentSenderTerminal => ({
  type: SET_CURRENT_SENDER_TERMINAL,
  terminal,
});

export const setCurrentReceiverTerminal = (terminal: TerminalCast | null): SetCurrentReceiverTerminal => ({
  type: SET_CURRENT_RECEIVER_TERMINAL,
  terminal,
});

export const setFastOrderCast = (payload: FastOrderCast | null): SetFastOrderCast => ({
  type: SET_FAST_ORDER_CAST,
  payload
})

export const resetSenderReceiverFast = (): ResetSenderReceiverFast => ({
  type: RESET_SENDER_RECEIVER_FAST
})

export const setFastOrder = (data: FastOrderCast): ThunkAction<Promise<any>> => 
(dispatch, getState, http) => {
  dispatch(setFastOrderCast(data))
  return Promise.resolve()
}

export const getRecentUsedCasts = (side: string, iso?: string): ThunkAction<Promise<CalculationCast[]>> => (dispatch, getState, http) => http.get(
  '/api/v1/contact/recent/',
  { side, iso },
).then(
  ({ items }: { items: CalculationCast[] }) => Promise.resolve(items),
);

export const getRecentAutocomplete = (filter: anyObject): ThunkAction<Promise<CalculationCast[]>> => (dispatch, getState, http) => http.get(
  '/api/v1/contact/autocomplete/',
  filter,
).then(
  ({ items }: { items: CalculationCast[] }) => Promise.resolve(items),
);

export const createCalculationCast = (data: ContactFormData, side: 'sender' | 'receiver'): ThunkAction => (dispatch) => {

  if (data.terminalId) {
    const { terminal, ...postData } = data;
    let f = complexForeignAddressCreate;
    let toSendData: any = postData;
    if (data.iso === 'RU') {
      f = contactCastComplexCreate;
      if (terminal?.code) {
        toSendData['terminalCode'] = terminal?.code;
      }
      if (terminal?.deliveryService) {
        toSendData['deliveryService'] = terminal?.deliveryService;
      }
    }
    return dispatch(f({
      ...adaptToApi(toSendData),
      side: `${side}_terminal`,
    })).then(
      ({ contactCastId }) => {
        const cast: CalculationSide = {
          cast: {
            ...(data as NoUndefinedField<ContactFormData>),
            contactCastId,
          },
          type: CalculationSideCastType.TERMINAL,
        };
        if (side === 'sender') dispatch(setSenderCast(cast));
        else dispatch(setReceiverCast(cast));
        return Promise.resolve(cast);
      },
    );
  }


  if (data.iso !== 'RU') {
    return dispatch(complexForeignAddressCreate({ ...adaptToApi(data), side: `${side}_inter` })).then(
      ({ addressCastId, contactCastId }) => {
        const cast: CalculationSide = {
          cast: {
            ...(data as NoUndefinedField<ContactFormData>), addressCastId, contactCastId, side,
          },
          type: CalculationSideCastType.FOREIGN,
        };

        if (side === 'sender') dispatch(setSenderCast(cast));
        else dispatch(setReceiverCast(cast));
        return Promise.resolve(cast);
      },
    );
  }

  return dispatch(contactCastComplexCreate({
    ...adaptToApi(data),
    side,
  })).then(
    ({ contactCastId, addressCastId }) => {
      const cast: CalculationSide = {
        cast: {
          ...(data as NoUndefinedField<ContactFormData>), addressCastId, contactCastId, side,
        },
        type: CalculationSideCastType.DOMESTIC,
      };
      if (side === 'sender') dispatch(setSenderCast(cast));
      else dispatch(setReceiverCast(cast));
      return Promise.resolve(cast);
    },
  );
};

export const startCalculation = (): ThunkAction<Promise<string>> => async (dispatch, getState, http) => {
  const calcReducer = getState()[MODULE_NAME];
  const { sender, receiver } = calcReducer as typeof calcReducer & {
    sender: CalculationSide,
    receiver: CalculationSide,
  };
  dispatch(setCalculationResults([]));
  dispatch(setCalculationDone(false));
  const { cargoId, additionalServices } = getState().cargo;
  const data: anyObject = {
    cargo_id: cargoId,
  };

  const inter = sender.cast.iso !== "RU" || receiver.cast.iso !== "RU";
  let endpoint: string;
  if (inter && sender.type !== CalculationSideCastType.TERMINAL && receiver.type !== CalculationSideCastType.TERMINAL) endpoint = '/api/v1/calculation/foreign/start/';
  else if (sender.type === CalculationSideCastType.TERMINAL && receiver.type === CalculationSideCastType.TERMINAL) {
    endpoint = '/api/v1/calculation/terminal_terminal/start/';
  } else if (sender.type === CalculationSideCastType.TERMINAL) endpoint = '/api/v1/calculation/from_terminal/start/';
  else if (receiver.type === CalculationSideCastType.TERMINAL) endpoint = '/api/v1/calculation/terminal/start/';
  else {
    endpoint = '/api/v1/calculation/start/';
    data.calc_terminal = true;
  }

  if (additionalServices.some((_) => _ === AdditionalServiceCode.RETURN)) {
    const [returnCargoResponse] = await Promise.all(executeAll([dispatch(createReturnCargo())]));

    if (returnCargoResponse.status === 'rejected') {
      return Promise.reject({ returnPackages: returnCargoResponse.errors.packages });
    }
    data.return_cargo_id = returnCargoResponse.response.cargoId;
    endpoint = '/api/v1/calculation/start/with_return/';
  }

  if (sender.type === CalculationSideCastType.TERMINAL) {
    data.sender_terminal_id = sender.cast.terminalId;
  } else {
    data.sender_address_id = sender.cast.addressCastId;
  }

  if (receiver.type === CalculationSideCastType.TERMINAL) {
    data.terminal_id = receiver.cast.terminalId;
    data.receiver_terminal_id = receiver.cast.terminalId;
  } else {
    data.receiver_address_id = receiver.cast.addressCastId;
  }

  const initialServiceFilters = additionalServices.filter((_) => !!_);
  dispatch(updateServicesFilter(initialServiceFilters, true));

  return http.post(endpoint, data).then(
    ({ singleTaskId }: { singleTaskId: string }) => {
      dispatch(setCalculationRunning(true));
      dispatch(setCalculationTaskId(singleTaskId));
      dispatch(setCalculationPollingTimer(setTimeout(() => {
        dispatch(calculationResultPolling(singleTaskId));
      }, 5000)));
      return Promise.resolve(singleTaskId);
    },
  );
};

export const getRecalcResultForTerminal = (taskResultId: string, {
  senderTerminalId,
  receiverTerminalId,
}: { senderTerminalId?: number | null, receiverTerminalId?: number | null }): ThunkAction<Promise<SingleRate>> => (dispatch, getState, http) => http.put(
  `/api/v1/calculation/${taskResultId}/terminals/recalc/`,
  { sender_terminal_id: senderTerminalId, receiver_terminal_id: receiverTerminalId },
);

export const createClaim = (comment: string, pickupDate?: moment.Moment): ThunkAction => (dispatch, getState, http) => {
  const calcReducer = getState()[MODULE_NAME];
  const { sender, receiver } = calcReducer as typeof calcReducer & {
    sender: CalculationSide,
    receiver: CalculationSide,
  };
  let endpoint: string;
  const { cargoId } = getState().cargo;
  if (sender.type === CalculationSideCastType.TERMINAL && receiver.type === CalculationSideCastType.TERMINAL) {
    endpoint = '/api/v1/shipping/claim/terminal_terminal/create/';
  } else if (sender.type === CalculationSideCastType.TERMINAL) endpoint = '/api/v1/shipping/claim/from_terminal/create/';
  else if (receiver.type === CalculationSideCastType.TERMINAL) endpoint = '/api/v1/shipping/claim/terminal/create/';
  else {
    endpoint = '/api/v1/shipping/claim/create/';
  }

  let senderTerminalId: any;
  let receiverTerminalId: any;

  if (sender.type === CalculationSideCastType.TERMINAL) {
    senderTerminalId = sender.cast.terminalId;
  }

  if (receiver.type === CalculationSideCastType.TERMINAL) {
    receiverTerminalId = receiver.cast.terminalId;
  }

  const data: anyObject = {
    comment,
    pickupDate: pickupDate ? pickupDate.format(SERVER_DATE_FORMAT) : '',
    senderContactId: sender?.cast?.contactCastId,
    receiverContactId: receiver?.cast?.contactCastId,
    senderTerminalId,
    receiverTerminalId,
    cargoId,
    problemType: 'calculation',

  };

  return http.post(endpoint, adaptToApi(data));
};

export const createFastCalculationClaim = (pickupDate: moment.Moment, deliveryService: string, tariff: string, rateType: RateTypeChoice): ThunkAction => async (dispatch, getState, http) => {
  await dispatch(updateCurrentCargo());
  
  const calcReducer = getState()[MODULE_NAME];
  const { sender, receiver } = calcReducer as typeof calcReducer & {
    sender: CalculationSide,
    receiver: CalculationSide,
  };
  let endpoint: string;
  const { cargoId } = getState().cargo;
  if (sender.type === CalculationSideCastType.TERMINAL && receiver.type === CalculationSideCastType.TERMINAL) {
    endpoint = '/api/v1/shipping/claim/terminal_terminal/create/';
  } else if (sender.type === CalculationSideCastType.TERMINAL) endpoint = '/api/v1/shipping/claim/from_terminal/create/';
  else if (receiver.type === CalculationSideCastType.TERMINAL) endpoint = '/api/v1/shipping/claim/terminal/create/';
  else {
    endpoint = '/api/v1/shipping/claim/create/';
  }

  let senderTerminalId: any;
  let receiverTerminalId: any;

  if (sender.type === CalculationSideCastType.TERMINAL) {
    senderTerminalId = sender.cast.terminalId;
  }

  if (receiver.type === CalculationSideCastType.TERMINAL) {
    receiverTerminalId = receiver.cast.terminalId;
  }

  const data: anyObject = {
    comment: `Ошибка при перерасчете ${deliveryService}, ${tariff}, ${DELIVERY_WAY_SERVICES[rateType].title}`,
    pickupDate: pickupDate.format(SERVER_DATE_FORMAT),
    senderContactId: sender?.cast?.contactCastId,
    receiverContactId: receiver?.cast?.contactCastId,
    senderTerminalId,
    receiverTerminalId,
    cargoId,
    problemType: 'calculation',

  };

  return http.post(endpoint, adaptToApi(data));
}

export const updateContacts = (data: { senderPassport?: string, receiverPassport?: string, senderInn?: string, receiverInn?: string, senderLegalAddress?: string, 
  receiverLegalAddress?: string, senderOpfFull?: string, receiverOpfFull?: string, senderCompanyName?: string, 
  receiverCompanyName?: string }): ThunkAction => async (dispatch, getState, http) => {
  const calcReducer = getState()[MODULE_NAME];

  const { sender, receiver } = calcReducer as typeof calcReducer & {
    sender: CalculationSide,
    receiver: CalculationSide,
  };

  let senderInfo = null;
  let receiverInfo = null;

  if (sender.cast.contactCastId) {
    if (data.senderPassport) {
      const [senderResponse] = await Promise.all(executeAll([dispatch(updateInternationalContactCast(adaptToApi({passport: data.senderPassport}), sender.cast.contactCastId))]));

      if (senderResponse.status === 'rejected') {
        return Promise.reject({senderPassport: senderResponse.errors.passport});
      }
  
      senderInfo = {
        passport: senderResponse.response.passport
      }
  
      const castSender: CalculationSide = {
        ...sender,
        cast: {
          ...(sender.cast as NoUndefinedField<ContactFormData>), contactCastId: senderResponse.response.contactInfoCastId, passport: senderResponse.response.passport, side: "sender",
        },
      };
      dispatch(setSenderCast(castSender));
    } else if (data.senderInn && data.senderLegalAddress && data.senderCompanyName) {
      const [senderResponse] = await Promise.all(executeAll([dispatch(updateInternationalContactCast(adaptToApi({inn: data.senderInn, legalAddress: data.senderLegalAddress, companyName: data.senderCompanyName, opfFull: data.senderOpfFull || "ООО"}), sender.cast.contactCastId))]));

      if (senderResponse.status === 'rejected') {
        return Promise.reject({senderInn: senderResponse.errors.inn});
      }

      senderInfo = {
        inn: senderResponse.response.inn,
        legalAddress: senderResponse.response.legalAddress,
        companyName: senderResponse.response.companyName,
        opfFull: senderResponse.response.opfFull, 
      }
    
      const castSender: CalculationSide = {
        ...sender,
        cast: {
          ...(sender.cast as NoUndefinedField<ContactFormData>), 
          contactCastId: senderResponse.response.contactInfoCastId, 
          inn: senderResponse.response.inn,
          legalAddress: senderResponse.response.legalAddress,
          companyName: senderResponse.response.companyName,
          opfFull: senderResponse.response.opfFull, 
          side: "sender",
        },
      };
      dispatch(setSenderCast(castSender));
    }

  }

  if (receiver.cast.contactCastId) {
    if (data.receiverPassport) {
      const [receiverResponse] = await Promise.all(executeAll([dispatch(updateInternationalContactCast(adaptToApi({passport: data.receiverPassport}), receiver.cast.contactCastId))]));

      if (receiverResponse.status === 'rejected') {
        return Promise.reject({receiverPassport: receiverResponse.errors.passport});
      }

      receiverInfo = {
        receiverPassport: receiverResponse.response.passport
      }

      const castReceiver: CalculationSide = {
        ...receiver,
        cast: {
          ...(receiver.cast as NoUndefinedField<ContactFormData>), contactCastId: receiverResponse.response.contactInfoCastId, passport: receiverResponse.response.passport, side: "receiver",
        },
      };

      dispatch(setReceiverCast(castReceiver));
    } else if (data.receiverInn && data.receiverLegalAddress && data.receiverCompanyName) {
      const [receiverResponse] = await Promise.all(executeAll([dispatch(updateInternationalContactCast(adaptToApi({inn: data.receiverInn, legalAddress: data.receiverLegalAddress, companyName: data.receiverCompanyName, opfFull: data.receiverOpfFull || "ООО"}), receiver.cast.contactCastId))]));

      if (receiverResponse.status === 'rejected') {
        return Promise.reject({receiverInn: receiverResponse.errors.inn});
      }

      receiverInfo = {
        inn: receiverResponse.response.inn,
        legalAddress: receiverResponse.response.legalAddress,
        companyName: receiverResponse.response.companyName,
        opfFull: receiverResponse.response.opfFull, 
      }
    
      const castReceiver: CalculationSide = {
        ...receiver,
        cast: {
          ...(receiver.cast as NoUndefinedField<ContactFormData>), 
          contactCastId: receiverResponse.response.contactInfoCastId, 
          inn: receiverResponse.response.inn,
          legalAddress: receiverResponse.response.legalAddress,
          companyName: receiverResponse.response.companyName,
          opfFull: receiverResponse.response.opfFull, 
          side: "receiver",
        },
      };
      dispatch(setReceiverCast(castReceiver));
    }

  }

  if ((!receiverInfo && (data.senderPassport || data.senderInn && data.senderLegalAddress && data.senderCompanyName)) ||
   (!receiverInfo && (data.receiverPassport || data.receiverInn && data.receiverLegalAddress && data.receiverCompanyName))) {
    return Promise.reject()
  }

  return ({
    sender: senderInfo,
    receiver: receiverInfo
  })
};

export const createAddressCast = (data: FastCalculationSide, side: string): ThunkAction => (dispatch, getState, http) => {
  return http.post('/api/v1/contact/cast/address/fast/create/', adaptToApi(data)).then(
    ({ addressCastId }: { addressCastId: string }) => {
      if (side === 'sender') dispatch(setSenderFastCast({...(data as NoUndefinedField<FastCalculationSide>), addressCastId}));
      else dispatch(setReceiverFastCast({...(data as NoUndefinedField<FastCalculationSide>), addressCastId}));
      return Promise.resolve({...(data as NoUndefinedField<FastCalculationSide>), addressCastId});
    },
  );
}

export const updateAddressCast = (rawData: ContactFormData, side: string, id: string): ThunkAction => (dispatch, getState, http) => {
  const data = {
    value: rawData.addressLine,
    postcode: rawData.postcode || "",
    iso: rawData.iso,
    region: rawData.region || "",
    district: rawData.district || "",
    city: rawData.city || "",
    street: rawData.street || "",
    house: rawData.house || "",
    latitude: rawData.latitude || 0,
    longitude: rawData.longitude || 0,
    cityType: rawData.city || "",
    fiasId: rawData.fiasId || "",
  }
  return http.patch(`/api/v1/contact/cast/address/fast/${id}/update/`, adaptToApi(data)).then(
    ({ addressCastId }: { addressCastId: string }) => {
      if (side === 'sender') dispatch(setSenderFastCast({...(data as NoUndefinedField<FastCalculationSide>), addressCastId}));
      else dispatch(setReceiverFastCast({...(data as NoUndefinedField<FastCalculationSide>), addressCastId}));
      return Promise.resolve({...(data as NoUndefinedField<FastCalculationSide>), addressCastId});
    },
  );
}

export const startFastCalculation = (): ThunkAction<Promise<string>> => async (dispatch, getState, http) => {
  const calcReducer = getState()[MODULE_NAME];
  const { senderFast, receiverFast } = calcReducer as typeof calcReducer & {
    senderFast: FastCalculationSide,
    receiverFast: FastCalculationSide,
  };
  dispatch(setCalculationResults([]));
  dispatch(setCalculationDone(false));
  const { cargoId } = getState().cargo;
  const data: anyObject = {
    cargo_id: cargoId,
  };

  const isInternational = senderFast.iso !== "RU" || receiverFast.iso !== "RU";

  const endpoint = isInternational ? '/api/v1/calculation/foreign/start/' : '/api/v1/calculation/start/';
  if (
    Object.keys(COUNTRIES_CIS).includes(senderFast.iso) &&
    Object.keys(COUNTRIES_CIS).includes(receiverFast.iso)
  ) {
    data.calc_terminal = true;
  }

  data.sender_address_id = senderFast.addressCastId;
  data.receiver_address_id = receiverFast.addressCastId;

  return http.post(endpoint, data).then(
    ({ singleTaskId }: { singleTaskId: string }) => {
      dispatch(setCalculationRunning(true));
      dispatch(setCalculationTaskId(singleTaskId));
      dispatch(setCalculationPollingTimer(setTimeout(() => {
        dispatch(calculationResultPolling(singleTaskId));
      }, 5000)));
      return Promise.resolve(singleTaskId);
    },
  );
};

export const startFastRecalculation = (deliveryService: string, recalcType: string): ThunkAction<Promise<string>> => async (dispatch, getState, http) => {
  const calcReducer = getState()[MODULE_NAME];
  const { senderFast, receiverFast } = calcReducer as typeof calcReducer & {
    senderFast: FastCalculationSide,
    receiverFast: FastCalculationSide,
  };
  dispatch(setRecalculationResults([]));
  dispatch(setRecalculationDone(false));
  const { cargoId } = getState().cargo;
  const data: anyObject = {
    cargo_id: cargoId,
  };

  let endpoint = '/api/v1/calculation/start/recalc/';

  data.sender_address_id = senderFast.addressCastId;
  data.receiver_address_id = receiverFast.addressCastId;
  data.delivery_services = [deliveryService]
  data.recalc_type = recalcType;

  return http.post(endpoint, data).then(
    ({ singleTaskId }: { singleTaskId: string }) => {
      dispatch(setRecalculationRunning(true));
      dispatch(setRecalculationTaskId(singleTaskId));
      return Promise.resolve(singleTaskId);
    },
  );
};

export const getAdditionalServicesByKeys = (rateKey?: string, targetKeys?: string[]): ThunkAction => (dispatch, getState, http) => {
  const { results } = getState().calculation;
  const currentRate = results.find(_ => getGroupName(_) === rateKey);
  const keys = targetKeys || currentRate?.additionalServices.map(_ => _.code);
  if (!keys) return Promise.reject();
  return http.get('/api/v1/calculation/additional_services/list/', { keys }).then(
    ({ items }: { items: any }) => {
      dispatch(setAdditionalServiceDescList(items))
      return Promise.resolve(items);
    },
  );
}

